import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { FlexboxGrid, Tag } from 'rsuite'
import Link from 'next/link'
import { MouseEvent } from 'react'
import { useRouter } from 'next/router'

import { getPricePerPieceUnit, getProductSalesCount, getSmallestBarcodeItemsMoq } from '../../lib/salesUnit'
import { getProductImageAltText, getProductName } from '../../lib/text'
import { Product } from '../../utils/types/Product'
import { formatPriceNumber } from '../../utils/math'
import usePrice from '../../services/usePrice'
import { getLargePreview, imageLoader } from '../../utils/images'
import ProductEmptyImage from './ProductEmptyImage'
import { getPathName } from '../../lib/product'
import useUrls from '../../services/useUrls'
import useGetAnalyticsLocation from '../../services/analytics/useGetAnalyticsLocation'
import productCardClickEvent from '../../utils/analytics/productCardClickEvent'
import { AnalyticsContext } from '../../utils/types/analytics'
import { getSupplierDeliveryDetails, isFreeDelivery } from '../../lib/service'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { deliveryHelper } from '../../utils/util'
import ProductCardButtons from '../Buttons/ProductCardButtons'
import useGetLocalizedProductUrl from './hooks/useGetLocalizedProductUrl'

import styles from '../../styles/ProductCardNarrow.module.less'
import tagStyles from '../../styles/Tag.module.less'

interface ProductCardNarrowProps {
  product: Product
  onAnalyticsClick?: (event: MouseEvent<HTMLElement>) => void
  analyticsContext?: AnalyticsContext
  hasBorder?: boolean
  isGrid?: boolean
  isSupplierNameVisible?: boolean
}

const ProductCardNarrow = (props: ProductCardNarrowProps) => {
  const {
    product,
    onAnalyticsClick,
    analyticsContext,
    hasBorder = false,
    isGrid = false,
    isSupplierNameVisible = true,
  } = props

  const { t } = useTranslation('products')
  const { urlT } = useUrls()
  const {
    default: {
      price,
      reference: fullReferencePrice,
    },
    final: {
      reference: finalReferencePrice,
    },
    discount: {
      lowest: lowestCustomTierDiscount,
    },
    currency,
    moq,
  } = usePrice(product, 1)

  const { locale: countryAndLocaleString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocaleString)
  const getLocalizedProductUrl = useGetLocalizedProductUrl()
  const analyticsLocation = useGetAnalyticsLocation('product_card_narrow')

  const isValidProduct = !!product.media?.length

  if (!isValidProduct) {
    return null
  }

  const productName = `${getProductName(product)}${getProductSalesCount(product, t)}`
  const referencePrice = formatPriceNumber(finalReferencePrice)
  const productPricingUnit = getPricePerPieceUnit(product)
  const productUrl = urlT(getPathName(product, t))
  const isRFQProduct = !price
  const { deliveryTime } = getSupplierDeliveryDetails(product.supplier[0], country)
  const freeShipping = isFreeDelivery(product, country)
  const supplierName = product.supplier[0]['supplier.label']

  // Add 5% to strikethrough if difference in MOQ price is not noticeable
  const strikeThroughPrice = formatPriceNumber(fullReferencePrice)
    === formatPriceNumber(lowestCustomTierDiscount?.referencePrice ?? finalReferencePrice)
    ? fullReferencePrice * 1.05
    : fullReferencePrice

  // NOTE: Used for both click and context menu, decouple if the logic branches out
  const handleProductCardClick = (event?: MouseEvent<HTMLElement>) => {
    if (!event) return

    // Analytics event
    onAnalyticsClick?.(event)
    productCardClickEvent({
      event,
      context: analyticsContext || analyticsLocation,
      product,
      country,
      getLocalizedProductUrl,
    })
  }

  const imageAltText = getProductImageAltText(product)
  const moqText = `${t('Minimum')} ${getSmallestBarcodeItemsMoq(product, t, moq)}`
  const image = product.media?.length ? (
    <Image
      loader={imageLoader}
      src={getLargePreview(product?.media?.[0])}
      alt={imageAltText}
      height={120}
      width={120}
    />
  ) : (
    <ProductEmptyImage
      height={120}
      width={120}
    />
  )

  return (
    <Link
      href={productUrl}
      onClick={handleProductCardClick}
      className={`
          ${styles['product-card-narrow-wrapper-link']} 
          ${isGrid ? styles['is-grid-item'] : ''}
        `}
    >
      <FlexboxGrid className={`${styles['product-card-narrow']} ${hasBorder ? styles['product-card-border'] : ''}`}>
        <FlexboxGrid.Item className={styles['image-container']}>
          {image}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          <Tag
            data-testid="product-card-narrow-tag"
            data-testvalue={isRFQProduct ? -1 : deliveryTime}
            className={`${tagStyles.tag}  ${tagStyles.bordered} ${styles.tag}`}
          >
            {freeShipping && !isRFQProduct && t('Free')}
            {' '}
            {isRFQProduct ? t('Quote only') : deliveryHelper(deliveryTime, t, freeShipping)}
          </Tag>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as="p"
          className={styles['product-name']}
        >
          {productName}
        </FlexboxGrid.Item>
        {!isRFQProduct && (
          <FlexboxGrid.Item
            as="p"
            className={styles['strike-through-price']}
          >
            {formatPriceNumber(strikeThroughPrice)}
            {' '}
            {currency}
            <span>
              {' '}
              /
              {' '}
              {t(productPricingUnit, { count: 1 })}
            </span>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item
          as="p"
          className={styles['reference-price']}
        >
          {referencePrice}
          {' '}
          {currency}
          {' '}
          <span>
            /
            {t(productPricingUnit, { count: 1 })}
          </span>
        </FlexboxGrid.Item>

        {isSupplierNameVisible && (
          <FlexboxGrid.Item
            as="p"
            className={styles['supplier-name']}
          >
            {supplierName}
          </FlexboxGrid.Item>
        )}

        <FlexboxGrid.Item
          as="p"
        >
          {moqText}
        </FlexboxGrid.Item>

        <FlexboxGrid.Item className={styles['product-card-buttons']}>
          <ProductCardButtons
            analyticsContext={analyticsContext || analyticsLocation}
            product={product}
            onClick={handleProductCardClick}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Link>
  )
}

export default ProductCardNarrow
